/** @format */

import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// Custom Components
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import {useLocation} from 'react-router-dom';
import {CirclePicker} from 'react-color';
import './upload.css';
import { Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width:'100%',
    height:'calc(100vh - 80px)',
    backgroundColor:'#EFEFEF'
  },
  paper: {
    backgroundColor:'white',
    padding:'1rem 2rem',
    borderRadius:'2rem',
    display:'flex',
    alignItems:'baseline',
    justifyContent:'space-between',
    marginBottom:'2rem'
  },
  paperSummary: {
    backgroundColor:'white',
    padding:'1rem 2rem',
    borderRadius:'2rem',
    // display:'flex',
  },
  uploadGrid: {
    display:'flex',
    marginTop: 50,
    justifyContent:'center'
  },
  uploader:{
    backgroundColor:'white',
    height:400,
    padding: '5rem',
    textAlign:'center',
    width:'50%',
    borderRadius:'2rem'
  },
  control: {
    padding: theme.spacing(2),
  },
  settings: {
    marginTop: 50,
    display:'flex',
    flexDirection:'column',
    // justifyContent:'space-between'
  },
  formControl:{
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200,
    textAlign:'center',
  },
  textField: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200,
    '& input':{
      textAlign:'center'
    }
  },
  InputAdornment:{
    '& .MuiTypography-colorTextSecondary':{
      color:'black'
    }
  }
}));

const PodPage = props => {
  const {cartUtils, isMobile} = props;
  const relatedItems = [];
  const [materials, setMaterials] = useState([]);
  const [material,setMaterial] = useState("PLA");
  const [infill,setInfill] = useState(20);
  const [qty,setQty] = useState(1);
  const [files, setFiles] = useState(null);
  const [supports, setSupports] = useState(false);

  const query = useQuery();
  const classes = useStyles();

  const defaultTags = {
    color: 'Black',
    material: 'PLA',
    weight: '1kg',
    diameter: '1.75mm',
    colorName: 'Black',
  };

  useEffect(() => {
    if (relatedItems[0] === undefined) {
      fetch(`/api/items/tags?key=weight&value=${defaultTags.weight}`)
        .then(resp => resp.json())
        .then(data => {
          let tmp = new Set();
          data.forEach(item=>{
            tmp.add(item.tags.material);
          })
          setMaterials([...tmp]);
        });
    }
    // eslint-disable-next-line
  }, []);
  const handleChange = (event) => {
    setMaterial(event.target.value);
  };

  return (
    <>
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={9} className={classes.uploadGrid}>
        <Paper className={classes.uploader} elevation={0}>
          <Typography variant='h3' color="primary">Upload your file</Typography>
          <Typography variant='body1'>Max file size is 100mb</Typography>
          <br style={{clear:'both'}}/>
          <br/>
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={false}
            maxFiles={1}
            server="/api/file"
            name="upfile"
            credits=""
            onprocessfile={(resp)=>{
              console.log(resp)
            }}
            labelIdle='<img src="/icons/upload.svg" alt="upload"/><br/>Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
          </Paper>
      </Grid>

      <Grid item xs={12} md={3} className={classes.settings}>
        <Paper className={classes.paper}>
          <Typography variant='body1'>MATERIAL</Typography>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              value={material}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {materials.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant='body1'>COLOR</Typography>
          <CirclePicker 
          colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
          />
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant='body1'>INFILL</Typography>
          <FormControl className={classes.textField}>
            <TextField
              id="infill"
              value={infill}
              type="number"
              min="0" 
              max="100"
              onChange={(e,val)=>{
                let tmp = Number(e.target.value);
                tmp = tmp>100?100:tmp;
                setInfill(tmp)
              }}
              InputProps={{
                endAdornment: <InputAdornment className={classes.InputAdornment}>%</InputAdornment>,
              }}
            />
          </FormControl>
        </Paper>
        <Paper className={classes.paper}>
            <Typography variant='body1'>QUANTITY</Typography>
            <div style={{display:'flex', alignItems:'center', }}>
              <IconButton
                disableRipple
                className="qty-btn remove"
                disabled={qty <= 1}
                onClick={e => {setQty(tmp=>tmp-1)}}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Typography id="qty-txt">{qty}</Typography>
              <IconButton
                disableRipple
                className="qty-btn add"
                onClick={e => {setQty(tmp=>tmp+1)}}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
        </Paper>
        <Paper className={classes.paper}>
            <Typography variant='body1' >SUPPORTS</Typography>
            <Checkbox
              checked={supports}
              color="primary"
              style={{color:'black'}}
              onChange={(e)=>{setSupports(e.target.checked)}}
              inputProps={{ 'aria-label': 'Suports' }}
            />
        </Paper>
        {supports && <Paper className={classes.paper}>
            <Typography variant='body1'>Support Type</Typography>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              value={material}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {materials.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>}
        <Paper className={classes.paperSummary}>
            <img src="https://place-hold.it/200x200" height="200" width="200" alt="preview" />
            <Typography variant='body2'>/EA</Typography>
            <Typography variant='body2'>$0.00</Typography>
            <br/>
            <Typography variant='body1'>TOTAL</Typography>
            <Typography variant='body1' color="primary">$0.00</Typography>
            <br/>
            <br/>
            <Button
              className={classes.button}
              // disabled={!(currentItem.qoh > 0)}
              // endIcon={<ShoppingCart size={16} />}
              fullWidth
              color="primary"
              onClick={e => {
              }}
              variant="contained">
              Get Quote
            </Button>
        </Paper>
      </Grid>
    </Grid>
    </>
  );
};

export default PodPage;
