/** @format */

import React, {useEffect, useState} from 'react';
import BlogStyles from './styles';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const categories = [
  'Communication',
  'Arts',
  'Hobbies',
  'Industry',
  'Science',
  'Nature',
  'Entertainment',
  'Personal',
  'Beauty',
];
const Blog = ({isMobile}) => {
  const pgStyles = BlogStyles(isMobile)();
  const [posts, setPosts] = useState([]);
  const [checked, setChecked] = React.useState([0]);
  useEffect(() => {
    function fetchProducts() {
      fetch('/api/blog/page/1?numPerPage=10')
        .then(response => response.json())
        .then(data => setPosts(data.posts));
      // setProducts(result);
    }
    fetchProducts();
    return function () {};
  }, []);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div style={{width: '100%', height: '100%'}}>
      <div className={pgStyles.bg}>
        <Typography variant='h1'>PrintBed Blog & 3D Printing Tips</Typography>
        <Typography variant='body1'>Welcome! Our 3D printing blog is designed with our customers and other 3D printing enthusiasts in mind. Here, we want to dive into all things 3D printing which includes the latest trends, 3D printing tips, and insights on the various filaments we offer and exciting upcoming news. So whether you're a beginner or an expert, our comprehensive guides, tips, reviews, and more will help you achieve the best results in your 3D printing projects and maybe learn something along the way!
        </Typography>
      </div>
      <div className={pgStyles.container}>
        <div style={{width:isMobile?'90%':'80%'}}>
          {posts.map(post => {
            return (
              <Link to={"/blog/"+post.slug+"/"}>
                <div className={pgStyles.posts}>
                  <div className="cat">{post.category.toTitleCase()}</div>
                  <h1>{post.title}</h1>
                  <h4>{new Date(post.date).toLocaleDateString()}</h4>
                  <div>{parse(post.preview.replace('/post/','/blog/'))}</div>
                </div>
              </Link>
            );
          })}
          <Typography variant='h2'>Why Choose PrintBed?</Typography>
          <Typography variant='body1'>At PrintBed, customer satisfaction remains a top priority in all things we do. That’s why we are committed to offering the highest quality <Link to="/">3D filament</Link> available, guaranteeing flawless, consistent coloring and perfectly wound spools every time.</Typography>
          <Typography variant='body1'>Whatever your project needs, PrintBed guarantees top performance and exceptional customer service, making sure that all of your needs are met. We also love staying connected to our customers, which is why we encourage you to tag us on social media to show us your projects and 3D printing tips! Start creating with PrintBed today!</Typography>
          <br/>
          <Typography variant='h2'>Sign Up For Premium!</Typography>
          <Typography variant='body1'>To show our gratitude for our customers and our desire to aid in your 3D printing endeavors, at PrintBed, we offer a premium subscription for just $25 a month. Premium members enjoy a monthly delivery of a tangle-free spool featuring exclusive PLA filaments that go beyond basic colors, offering a diverse selection that includes special varieties like <Link to="/item/PLA Ltd/Glow Stick Green-1.75mm-1kg/">glow-in-the-dark</Link> PLA! Premium subscription includes other benefits that are hard to pass up such as 10% on all other filaments, free ground shipping, voting on colors, and more! Don’t hesitate and become a Premium member to reap the rewards and take your projects to the next level!</Typography>
          <Link to="/premium/">Sign Up For Our Premium Filament Subscription!</Link>
        </div>
      </div>
    </div>
  );
};

export default Blog;
