/** @format */

import {makeStyles} from '@material-ui/core/styles';

const ItemStyles = makeStyles(
  {
    addToCartButton: {
      borderRadius: '4px',
      backgroundColor: '#116df9',
      color: 'white',
      fontSize: '2rem',
      height: '100%',
      lineHeight: '1.2',
      padding: '2rem 3rem',
    },
    title: {
      fontSize:'2.5rem',
      fontWeight:600,
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      lineHeight: 1.334,
    },
    availabilityTxt: {
      alignItems: 'flex-end',
      display: 'flex',
      lineHeight: '1.75',
      '&:hover': {
        userSelect: 'none',
      },
    },
    checkBox: {
      color:'#eee',
      '& .Mui-Checked': {
        color:'inherit'
      }
    },
    groundShipping: {
      alignItems: 'flex-end',
      display: 'flex',
      lineHeight: '1.75',
      // border:'1px solid lightgray',
      // borderRadius:5,
      marginTop:10,
      // padding:5,
      '&:hover': {
        userSelect: 'none',
      },
    },
    descContainer: {
      marginTop: '1.48vh',
      maxWidth: '100%',
      paddingRight: '5rem',
      position: 'relative',
      width: 'inherit',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // display: '-webkit-inline-box',
      // '-webkit-box-orient': 'vertical',
      // '-webkit-line-clamp': 3,
    },
    extraInfo: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& .info-spacer': {
        flexGrow: 1,
      },
      '& .info-wrapper': {
        display: 'grid',
        gridTemplateRows: '1fr 1fr 1fr',
      },
      '& .txt': {
        display: 'flex',
        alignItems: 'flex-end',
        lineHeight: '1.75',
      },
    },
    imgViewer: {
      flex: 1,
    },
    itemStock: {
      '&.in-stock': {
        color: '#116df9',
      },
      '&.out-of-stock': {
        color: 'red',
      },
    },
    optionContainer: {
      flex: '0 0 15%',
      margin: '0 0.5rem 0.5rem 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      '&.selected': {
        borderColor: '#24A9DF',
      },
    },
    optionChoice: {
      height: '5vh',
      border: '2px solid transparent',
      borderRadius: 4,
      backgroundColor: '#f5f5f5',
      padding: '0.925vh 1.25rem',
      fontWeight: 'bold',
      fontSize: '2rem',
      '& .color-div': {
        width: '100%',
        height: '100%',
        borderRadius: '4px',
      },
      '&.color': {
        backgroundColor: '#eee',
        height: '8rem',
        width: '8rem',
        '&.Mui-disabled': {
          filter: 'opacity(25%)',
        },
        // maxWidth: '5.92vh',
        // minWidth: '5.92vh',
        maxWidth: '8rem',
        minWidth: '8rem',
      },
      '& .MuiButton-label': {
        height: '100%',
        width: '100%',
      },
      '&.selected': {
        borderColor: '#24A9DF',
      },
    },
    optionWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '54rem',
      marginBottom: '3rem',
      position: 'relative',
      '& .opt-name': {
        display: 'flex',
        flexGrow: '1',
        flexBasis: '100%',
        margin: '0 0 3rem 0',
        textTransform: 'capitalize',
        userSelect: 'none',
        '& .color-name': {
          fontWeight: 'bold',
        },
      },
      '&:last': {
        marginBottom: '10vh',
      },
    },
    infoTabs: {
      clear: 'both',
    },
    overviewDiv: {
      display: 'flex',
      flexBasis: '80vh',
      flexDirection: 'row',
      height: '100%',
      justifyContent: 'center',
      marginBottom: '9.26vh',
      // maxHeight: '80vh',
      // minHeight: '70vh',
      padding: '0 10.5vw',
      '& .content': {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
      },
      '& .item-details': {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        marginLeft: '6rem',
        maxWidth: '55rem',
        minHeight: 1000,
      },
      '& .spacer': {
        height: '100%',
        width: '100%',
        flexBasis: 0,
        flexGrow: 1,
      },
    },
    price: {
      marginBottom: '5rem',
    },
    quantityButton: {
      color: '#116df9',
      flex: '2',
      fontSize: '3rem',
      minWidth: '0',
      '&:hover': {
        backgroundColor: 'initial',
      },
    },
    quantitySelection: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      height: 'min-content',
      justifyContent: 'flex-start',
      marginTop: '5rem',
      width: '100%',
      '& .qty': {
        userSelect: 'none',
        width: '3rem',
        textAlign: 'center',
      },
      '& .qty input': {
        textAlign: 'center',
      },
      '& .qty-btns': {
        alignItems: 'center',
        border: '2px solid #EEEEEE',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        marginBottom: '5rem',
        maxHeight: '6rem',
        width: '13.5rem',
      },
    },
    rating: {
      margin: '2rem 0',
    },
    readMore: {
      margin: '0.5rem 0 3rem',
      fontWeight: '500',
      color: '#116df9',
      '&:visited, &:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    saleInfo: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '6rem',
      width: '37.5rem',
    },
    socialBtns: {
      display: 'flex',
      alignItems: 'flex-end',
      '& .social-button': {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '9.25vh',
      '& .divider': {
        margin: '2.22vh 0',
      },
      '& .item-details .divider': {
        maxWidth: '58rem',
      },
    },
  },
  {name: 'item'}
);

export const ImageStyles = makeStyles(
  {
    dialog: {
      height: '100vh',
      width: '100vw',
      backdropFilter: 'blur(10px)',
      '& .dialog-paper': {
        height: '100vh',
        width: '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: 'transparent',
        '& .nav-btn': {
          backgroundColor: '#fff',
          border: '1px solid #eee',
          boxShadow: '1px 2px 5px #00000008',
          padding: '1rem',
          height: '100%',
          width: '100%',
          maxHeight: '5rem',
          maxWidth: '5rem',
          minWidth: '0',
          '&#fs-prev': {
            marginRight: '3.5rem',
          },
          '&#fs-next': {
            marginLeft: '3.5rem',
          },
          '&.Mui-disabled': {
            filter: 'opacity(25%)',
          },
          '& img': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    imagePreviews: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      padding: '0',
      width: '10rem',
      '.dialog-paper &': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'visible',
        width: '100%',
        backgroundColor: '#fff',
        padding: '2rem 0',
        margin: 0,
      },
    },
    itemImage: {
      width: '100%',
      maxWidth: '15rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    itemVideo: {
      width: '100%',
      height: '100%',
      maxWidth: '15rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    mainImg: {
      alignItems: 'center',
      backgroundColor: '#fff',
      border: '1px solid #eee',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      height: '31.25vw',
      justifyContent: 'center',
      marginLeft: '1vw',
      padding: '2rem 1.5rem',
      position: 'relative',
      width: '31.25vw',
      '&:before': {
        content: '',
        display: 'block',
        paddingTop: '100%',
      },
      '& .img-wrapper': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
        '& img': {
          height: 'max-content',
          width: 'fit-content',
          maxWidth: '100%',
        },
        '& video': {
          height: '100%',
          maxWidth: '100%',
        },
      },
      '.dialog-paper &': {
        height: '75vh',
        backgroundColor: 'transparent',
        width: '100%',
        margin: 0,
        padding: 0,
      },
    },
    minimize: {
      position: 'absolute',
      top: '2.5rem',
      right: '2.5rem',
    },
    navBtns: {
      display: 'flex',
      height: '5rem',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      marginBottom: '1.5rem',
      padding: '0 1rem',
      '& .btn': {
        height: '5rem',
        width: '5rem',
        minWidth: '1rem',
        backgroundColor: '#fff',
        marginLeft: '1rem',
        '&.Mui-disabled': {
          filter: 'opacity(25%)',
        },
        '& img': {
          height: '100%',
          width: '100%',
        },
      },
      '& .fullscreen': {
        flexGrow: '1',
      },
    },
    thumbnailWrapper: {
      alignItems: 'center',
      backgroundColor: '#fff',
      border: '2px solid #eee',
      borderRadius: '4px',
      display: 'flex',
      height: '100%',
      listStyleType: 'none',
      marginBottom: '2.5rem',
      maxHeight: '8.75rem',
      maxWidth: '8.75rem',
      width: '100%',
      '.dialog-paper &': {
        marginBottom: 0,
        height: '100%',
        marginRight: '1.5rem',
        marginLeft: '1.5rem',
      },
      '&.current': {
        border: '2px solid #24A9DF',
        listStyleType: 'none',
      },
    },
    wrapper: {
      display: 'flex',
      flex: '1.5',
      maxHeight: '31.25vw',
      maxWidth: '35.3vw',
      '& .dialog': {
        height: '75vh',
        width: '60vw',
      },
    },
  },
  {name: 'image-viewer'}
);

export default ItemStyles;
