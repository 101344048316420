export const structuredDataSingle = (item,url) => {
    if(!item) {
        return JSON.stringify({});
    }
    let data = {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": item.name,
        "description": item.desc,
        "sku": item.id,
        "image": item.imgSrc[0],
        "brand": {
          "@type": "Brand",
          "name": "PrintBed"
        },
        "weight": {
          "@type": "QuantitativeValue",
          "value": 1,
          "unitText": "kg"
        },
        "itemCondition": "http://schema.org/NewCondition",
        "color": item.tags.color,
        "offers": {
          "@type": "Offer",
          "priceCurrency": "USD",
          "price": item.price/100,
          "availability": item.qoh>0?"http://schema.org/InStock":"https://schema.org/OutOfStock",
          "url": url,    
          "priceValidUntil": "2024-12-31",
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "Standard Return Policy",
            "url": "https://www.printbed.com/return-policy",
            "applicableCountry": {
              "@type": "Country",
              "name": "US"
            },
            "returnPolicyCategory": "http://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": "30",
            "returnFees": "http://schema.org/ReturnShippingFees",
            "returnMethod": "http://schema.org/ReturnByMail",
            "returnShippingFeesAmount": {
              "@type": "MonetaryAmount",
              "value": "5.00",
              "currency": "USD"
            }
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "10.00",
              "currency": "USD"
            },
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 0,
                "maxValue": 0,
                "unitCode": "DAY"
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 5,
                "unitCode": "DAY"
              }
            },
            "shippingDestination": {
              "@type": "DefinedRegion",
              "addressCountry": "US"
            }
          }
        },
        // "review": {
        //   "@type": "Review",
        //   "author": {
        //     "@type": "Person",
        //     "name": "Customer Name"
        //   },
        //   "datePublished": "2024-01-01",
        //   "reviewBody": "This is a placeholder review. Replace with actual customer feedback.",
        //   "reviewRating": {
        //     "@type": "Rating",
        //     "ratingValue": "5",
        //     "bestRating": "5"
        //   }
        // },
        // "aggregateRating": {
        //   "@type": "AggregateRating",
        //   "ratingValue": "4.5",
        //   "reviewCount": "20"
        // }
      }
      

    return JSON.stringify(data);
};