/** @format */

import React from 'react';
import {Link} from 'react-router-dom';
// MUI Components
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import {styled} from '@material-ui/core/styles';

import PhoneIcon from '@material-ui/icons/Phone.js';
import MailIcon from '@material-ui/icons/Mail.js';
import {useHistory} from 'react-router-dom';
import Cookies from 'universal-cookie';

// Feather Icons
import {ArrowLeft, ShoppingCart} from 'react-feather';
// Styles
import MenuDrawerStyles from './styles';

const cookies = new Cookies();

const Spacer = styled('hr')(
  {
    color: 'black',
    width: '100%',
    border: 'solid 1px lightgrey',
  },
  {name: 'spacer'}
);

const MenuDrawer = props => {
  const pgStyles = MenuDrawerStyles();
  const {isDrawerOpen, userData, cartData, onClose} = props;
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    cookies.remove('JWT');
    cookies.remove('USER');
    window.location.href = '/';
    onClose();
  };

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: pgStyles.drawerPaper,
      }}
      open={isDrawerOpen}>
      <div className={pgStyles.drawerContent}>
        <Toolbar className={pgStyles.toolbar}>
          <Button
              className={pgStyles.backButton}
              onClick={() => {
                history.push('/');
                onClose();
              }}>
              <ArrowLeft />
            </Button>
          <Button
            className={pgStyles.toolbarButton}
            disableRipple
            href="callto:18334452600">
            <PhoneIcon />
          </Button>
          <Button
            className={pgStyles.toolbarButton}
            disableRipple
            href="mailto:support@printbed.com">
            <MailIcon />
          </Button>
          <div>
            {/* <Button>
              <Search />
            </Button> */}
            <Button
              onClick={() => {
                history.push('/cart');
              }}>
              <Badge badgeContent={cartData.length} color="secondary">
                <ShoppingCart />
              </Badge>
            </Button>
          </div>
        </Toolbar>
        {userData.loggedIn ? (
          <div className={pgStyles.accountInfo}>
            <Link to="/account/">
              <Avatar
                variant="circular"
                src={userData.avatar || '/icons/default_avatar.svg'}
                className={pgStyles.avatarBtn}
              />
            </Link>
            <div>
              <div>{`${userData.fname} ${userData.lname}`}</div>
              <div className={pgStyles.email}>{userData.email}</div>
            </div>
          </div>
        ) : (
          <Link
            className={`${pgStyles.link} ${pgStyles.signInLink}`}
            to="/modal/login"
            //
          >
            Sign In
          </Link>
        )}
        <div className={pgStyles.links}>
          {userData.loggedIn ? (
            <div className={pgStyles.mainLinks}>
              <Link
                className={pgStyles.link}
                to="/shop/"
                onClick={() => onClose(false)}>
                Store
              </Link>
              <Link
                className={pgStyles.link}
                to="/premium/"
                onClick={() => onClose(false)}>
                Premium
              </Link>
              <Link
                className={pgStyles.link}
                to="/process/"
                onClick={() => onClose(false)}>
                Process
              </Link>
              <Link
                className={pgStyles.link}
                to="/faq/"
                onClick={() => onClose(false)}>
                Help
              </Link>
              <Link
                className={pgStyles.link}
                to="/about/"
                onClick={() => onClose(false)}>
                About
              </Link>
              <Spacer />
              <Link
                className={pgStyles.link}
                to="/account/profile"
                onClick={() => onClose(false)}>
                Account
              </Link>
              <Link
                className={pgStyles.link}
                to="/account/orders"
                onClick={() => onClose(false)}>
                Order History
              </Link>
              <Link
                className={pgStyles.link}
                to="/account/payment"
                onClick={() => onClose(false)}>
                Payment Methods
              </Link>
              <Link
                className={pgStyles.link}
                to="/account/subscription"
                onClick={() => onClose(false)}>
                Subscriptions
              </Link>
              <Link
                className={pgStyles.link}
                to="/account/shipping"
                onClick={() => onClose(false)}>
                Shipping Info
              </Link>
            </div>
          ) : (
            <div className={pgStyles.mainLinks}>
              <Link
                className={pgStyles.link}
                to="/shop/"
                onClick={() => onClose(false)}>
                Store
              </Link>
              <Link
                className={pgStyles.link}
                to="/premium/"
                onClick={() => onClose(false)}>
                Premium
              </Link>
              <Link
                className={pgStyles.link}
                to="/process/"
                onClick={() => onClose(false)}>
                Process
              </Link>
              <Link
                className={pgStyles.link}
                to="/blog/"
                onClick={() => onClose(false)}>
                Blog
              </Link>
              <Link
                className={pgStyles.link}
                to="/help/"
                onClick={() => onClose(false)}>
                Help
              </Link>
              <Link
                className={pgStyles.link}
                to="/about/"
                onClick={() => onClose(false)}>
                About
              </Link>
            </div>
          )}
          <Spacer />
          <div className={pgStyles.bottomLinks}>
            <Link
              className={pgStyles.link}
              to="/tos/"
              onClick={() => onClose(false)}>
              Terms of Service
            </Link>
            <Link
              className={pgStyles.link}
              to="/privacy/"
              onClick={() => onClose(false)}>
              Privacy Policy
            </Link>
            {userData.loggedIn && (
              <Link className={pgStyles.link} to="/" onClick={logout}>
                Log Out
              </Link>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
