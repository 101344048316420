/** @format */

import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

// Custom imported components
import SubBanner from './subBanner';
import BestSellers from '../bestSellers';
import {Socials} from '../homeBanners';
import * as CustomBanners from '../homeBanners/customBanners';
// const ChatComp = lazy(() => import('../../components/chat'));
// Material UI
import {styled} from '@material-ui/core/styles';
import AnimateHeight from '../animateheight';
import { Typography } from '@material-ui/core';
//#region Styles

const ContentWrapper = styled('div')(
  {
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: '2',
    // marginTop: -80,
  },
  {name: 'content-wrap'}
);

const HomeWrapper = styled('div')(
  {
    position: 'relative',
    maxWidth:'100vw',
    overflow:'hidden'
  },
  {name: 'home-wrapper'}
);

const TextWrapper = styled('div')({
  position: 'relative',
  textAlign: 'center',
  padding: '2rem 40rem 0rem 40rem',
  '& h1':{
    fontSize:'6rem',
    fontWeight:'bold'
  },
},{name:'text-wrapper'})

const MobileTextWrapper = styled('div')({
  position: 'relative',
  textAlign: 'center',
  fontSize: '9rem',
  padding: '5%',
  '& h1':{
    fontSize:'10rem',
    fontWeight:'bold'
  },
  '& h2':{
    fontSize:'10rem'
  },
  '& h3': {
    textAlign:'justify',
    fontWeight:'normal',
  }
},{name:'text-wrapper'});

//#endregion
let headerRatio = 3780 / 2128;

export default function HomeComp(props) {
  const {isMobile} = props;
  const history = useHistory();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const goToStore = () => {
    history.push('/shop/');
  };
  const goToBulk = () => {
    history.push('/item/bulk/');
  };
  const goToPremium = () => {
    history.push('/premium/');
  };

  return (
    <HomeWrapper>
      {isMobile?
       <div><a onClick={goToStore}>
       <img className="Sirv" data-src="https://cdn.sunsh1n3.com/home/HeroBanners/main-banner-mobile.jpg" /></a>
     </div>:
      <AnimateHeight delay={1} >
        {navigator.userAgent !== 'ReactSnap' && <div
          id="NestedSirv"
          className="Sirv"
          style={{marginBottom: -80}}
          data-options="zoom.hint.enable:false;zoom.wheel:false;zoom.trigger:dblclick;fullscreen.enable:false;thumbnails.type:bullets;slide.animation.type:slide;slide.autoplay:true;slide.delay:8000;quality:90;hdQuality:80">
          <div>
            <a onClick={goToStore}>
              <img data-src="https://cdn.sunsh1n3.com/home/HeroBanners/main-banner.jpg" />
            </a>
          </div>
          {/* <div
            data-src="https://cdn.sunsh1n3.com/videos/header_banner.mp4"
            data-options="autoplay:true;"></div> */}
          <div>
            <a onClick={goToPremium}>
              <img data-src="https://cdn.sunsh1n3.com/Images/HeroBanners/premium.jpg" />
            </a>
          </div>
          <div>
            <a onClick={goToBulk}>
              <img data-src="https://cdn.sunsh1n3.com/Images/HeroBanners/bulk.jpg" />
            </a>
          </div>
          <div>
            <a onClick={goToStore}>
              <img data-src="https://cdn.sunsh1n3.com/Images/HeroBanners/store.jpg" />
            </a>
          </div>
          <div>
            <img data-src="https://cdn.sunsh1n3.com/Images/HeroBanners/overnight.jpg" />
          </div>
          <div>
            <img data-src="https://cdn.sunsh1n3.com/Images/HeroBanners/usa.jpg" />
          </div>
        </div>}
      </AnimateHeight>}
      <ContentWrapper>
        {isMobile?
        <MobileTextWrapper>
          <Typography variant='h1'>Get Creative with 3D Printer Filament from PrintBed</Typography>
          <Typography variant='h3'>PrintBed is a supplier of high-quality 3D filaments made and sourced in the USA. Our focus is on providing tangle-free spools that are color-consistent, ensuring a high level of precision and accuracy during printing.</Typography>
        </MobileTextWrapper>:
        <TextWrapper>
          <Typography variant='h1'>Get Creative with 3D Printer Filament from PrintBed</Typography>
          <Typography variant='body1'>PrintBed is a supplier of high-quality 3D filaments made and sourced in the USA. Our focus is on providing tangle-free spools that are color-consistent, ensuring a high level of precision and accuracy during printing.</Typography>
        </TextWrapper>}
        {!isMobile && <SubBanner />}
        <BestSellers isMobile={isMobile} />
        <CustomBanners.PremiumBanner isMobile={isMobile} />
        <CustomBanners.LimitedTogether isMobile={isMobile} />
        {/* <CustomBanners.MainBanner isMobile={isMobile}/> */}
        <CustomBanners.MerchBanner isMobile={isMobile} />
        <CustomBanners.ForgeBanner isMobile={isMobile} />
        <CustomBanners.MinisBanner isMobile={isMobile} />
        <CustomBanners.StarterKitBanner isMobile={isMobile} />
        <CustomBanners.MicroswissBanner isMobile={isMobile} />
        {!isMobile && <Socials />}
      </ContentWrapper>
    </HomeWrapper>
  );
}
