/** @format */

import React from 'react';
import {Link} from 'react-router-dom';
// Material UI Components
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
// import PinterestIcon from '@material-ui/icons/Pinterest';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FooterStyles from './styles.js';
const Footer = props => {
  const pgStyles = FooterStyles();

  return (
    <div className={pgStyles.root}>
      <div>
        <h1>
          <img
            src="https://cdn.sunsh1n3.com/Images/logos/pb-white-2023.svg"
            alt=""
            style={{
              width: '50vw',
            }}
          />
          <br />
          <div className={pgStyles.buttonGroup}>
            <IconButton
              className={pgStyles.socialButton}
              href="https://www.facebook.com/printbed/"
              target="_blank"
              rel="noopener noreferrer">
              <FacebookIcon />
            </IconButton>
            <IconButton
              className={pgStyles.socialButton}
              href="https://www.twitter.com/printbed"
              target="_blank"
              rel="noopener noreferrer">
              <TwitterIcon />
            </IconButton>
            <IconButton
              className={pgStyles.socialButton}
              href="https://www.instagram.com/print_bed"
              target="_blank"
              rel="noopener noreferrer">
              <InstagramIcon />
            </IconButton>
            {/* <IconButton
                        href='https://www.pinterest.com'
                        target='_blank'
                        rel='noopener noreferrer'
                        className={pgStyles.socialButton}
                    >
                        <PinterestIcon />
                    </IconButton>*/}
            <IconButton
              href="https://www.youtube.com/channel/UCBsoiOZE9kGJU6W5E6SxBDA"
              target="_blank"
              rel="noopener noreferrer"
              className={pgStyles.socialButton}>
              <YouTubeIcon />
            </IconButton>
            <IconButton
              href="https://discord.gg/XqsxbkqPQM"
              target="_blank"
              rel="noopener noreferrer"
              className={pgStyles.socialButton}>
              <img
                style={{width: 21, height: 21}}
                src="/icons/Discord_icon.svg"
                alt="discord"
              />
            </IconButton>
          </div>
        </h1>
      </div>
      <Link to="/premium/" className={pgStyles.footerLink}>
        <div>Premium</div> <div>+</div>
      </Link>
      <Link to="/about/" className={pgStyles.footerLink}>
        <div>About Us</div> <div>+</div>
      </Link>
      {/* <Link to="/cardsv2/" className={pgStyles.footerLink}>
        <div>Promotions</div> <div>+</div>
      </Link> */}
      <Link to="/process/" className={pgStyles.footerLink}>
        <div>The Process</div> <div>+</div>
      </Link>
      <Link to="/tos/" className={pgStyles.footerLink}>
        <div>Terms of Service</div> <div>+</div>
      </Link>
      <Link to="/privacy/" className={pgStyles.footerLink}>
        <div>Privacy Policy</div> <div>+</div>
      </Link>
      <a href="mailto:support@printbed.com" className={pgStyles.footerLink}>
        <div>Support</div> <div>+</div>
      </a>
      <a href="tel:18334452600" className={pgStyles.footerLink}>
        <div>1 (833) 445-2600</div> <div>+</div>
      </a>
      <p className={pgStyles.footerCopy}>
        © PrintBed, {new Date().getFullYear()}
      </p>
    </div>
  );
};

export default Footer;
